import React from "react"

export default function AmenThreeIcon({ color = "#656b68", className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 90"
      className={className}
      style={{ fill: color }}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect width="87.57" height="9.05" />
          <rect x="9.05" y="22.62" width="9.04" height="64.94" />
          <rect x="69.48" y="22.62" width="9.05" height="64.94" />
          <polygon points="0 13.97 0 18.9 41.32 18.9 41.32 41.32 36.4 41.32 36.4 51.17 41.32 51.17 41.32 73.59 23.02 73.59 23.02 82.64 64.55 82.64 64.55 73.59 46.25 73.59 46.25 51.17 51.17 51.17 51.17 41.32 46.25 41.32 46.25 18.9 87.57 18.9 87.57 13.97 43.79 13.97 0 13.97" />
        </g>
      </g>
    </svg>
  )
}
