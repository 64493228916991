import React from "react"

export default function TwoIcon({ color = "white", className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 248.39 72"
      className={className}
      style={{ fill: color }}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polygon points="76.75 11.49 69.32 11.49 69.32 60.52 73.04 60.52 76.75 60.52 76.75 11.49" />
          <polygon points="180.11 11.49 172.67 11.49 172.67 60.52 176.39 60.52 180.11 60.52 180.11 11.49" />
          <polygon points="7.44 11.49 3.72 11.49 0 11.49 0 60.52 7.44 60.52 7.44 11.49" />
          <polygon points="240.95 11.49 240.95 60.52 248.39 60.52 248.39 11.49 244.67 11.49 240.95 11.49" />
          <rect y="64.56" width="248.39" height="7.44" />
          <rect width="248.39" height="7.44" />
        </g>
      </g>
    </svg>
  )
}
