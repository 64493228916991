import React from "react"

import Image from "gatsby-image"
import CarouselProvider from "../carousel/carousel-provider"
import CarouselSlider from "../carousel/carousel-slider"
import CarouselArrow from "../carousel/carousel-arrow"

export default function RoundCarousel({ materials }) {

  //alert(typeof(materials))
  //alert(JSON.stringify(materials))

  return (
    <div>
      <CarouselProvider>
        <div className="relative flex flex-col w-full">
          <CarouselArrow
            className="absolute left-0"
            size="50"
            direction="left"
            style={{
              top: "65px",
              left: "-40px",
            }}
          ></CarouselArrow>

          <CarouselArrow
            className="absolute right-0"
            size="50"
            style={{
              top: "65px",
              right: "-40px",
            }}
          ></CarouselArrow>

          <div
            style={{ width: "180px", height: "180px" }}
            className="rounded-full overflow-hidden"
          >
            <CarouselSlider width="180px" height="180px">
              {materials.map(({ img, desc }, i) => {
                return (
                  <div className="" key={i}>
                    <img className="rounded-full" style={{ width: "180px", height: "180px", "max-width": "200px" }} src={img} alt={desc}></img>
                  </div>
                )
              })}
            </CarouselSlider>
          </div>

          <CarouselSlider width="180px" height="100%">
            {materials.map(({ desc }) => (
              <div
                className="w-full flex-none text-center mt-2 text-sm"
                key={desc}
              >
                {desc}
              </div>
            ))}
          </CarouselSlider>
        </div>
      </CarouselProvider>
    </div>
  )
}

// export const query = graphql`
//   query GetProductSwatchQuery($slug: String!) {
//     markdownRemark(fields: { slug: { eq: $slug } }) {
//       frontmatter {
//         materials {
//           cabinets {
//             desc
//             img {
//               childImageSharp {
//                 fluid(maxWidth: 300, quality: 80) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//           exterior {
//             desc
//             img {
//               childImageSharp {
//                 fluid(maxWidth: 300, quality: 80) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//           interior {
//             desc
//             img {
//               childImageSharp {
//                 fluid(maxWidth: 300, quality: 80) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//       fields {
//         slug
//       }
//     }
//   }
// `
