import React, { createRef, useState, useEffect } from "react"
import Image from "gatsby-image"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import HeroSection from "../components/hero-section"
import ProductIcons from "../components/products/product-icons"
import AmenIcons from "../components/products/product-amenities-icons"
import RoundCarousel from "../components/products/round-carousel"
import StickyMobileCta from "../components/call-to-action-qualify/sticky-mobile-cta"
import "./product.css"
import ProductImages from "../components/products/product-images"
import ProductTour from "../components/products/product-tour"
import SEO from "../components/seo"

const ProductTemplate = ( data ) => {

  //alert ("product_random " + JSON.stringify(data.pathContext.product.main_webpic))
  const product = data.pathContext.product;
  const product_features = JSON.parse(data.pathContext.product.features);

  //alert (JSON.stringify(product_features))

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
      var data = new FormData();
      data.append("event_type", event_type);
      data.append("event_name", event_name);

      const queryString = typeof window !== "undefined" ? window.location.search : ""
      const urlParams = new URLSearchParams(queryString);
      var cid = urlParams.get("cid");
      data.append("cid", cid);

      var xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
      xhr.send(data);
  }

  //LOGIC PER PRODUCT 
  if ( product.link == "modal-pod") { useEffect(() => {addWebEvent("visit", "products/modal-pod")},[]); var product_name = "Pod"}
  if ( product.link == "modal-00") { useEffect(() => {addWebEvent("visit", "products/modal-00")},[]); var product_name = "00"}
  if ( product.link == "modal-01") { useEffect(() => {addWebEvent("visit", "products/modal-01")},[]); var product_name = "01"}
  if ( product.link == "modal-02") { useEffect(() => {addWebEvent("visit", "products/modal-02")},[]); var product_name = "02"}
  

  return (
    <>
      <SEO title={"Modal " + product_name} />
      <Layout>
      <HeroSection className="flex flex-wrap md:flex-no-wrap items-center bg-gray-700">
        <div className="w-full lg:w-1/2 ml-5 text-white md:ml-32">
          <h1 className="font-light tracking-wide mb-16 pt-20 lg:pt-0">
            {product.tagline}
          </h1>

          <Link
            to="/design-builder"
            className="transition transition-delay-longer bg-white text-gray-600 border border-white hover:text-white hover:bg-transparent mt-12 px-8 py-3 text-center font-bradford1 tracking-wide modal-button hidden md:inline-block"
          >
            Design Your Modal
          </Link>
        </div>
        <div className="w-full lg:w-1/2">
          <h2 className="modal-header-number relative text-gray-400 font-maxeville absolute hidden md:block pl:20 text-center">
            {product_name}
          </h2>
          <img src={product.main_webpic} alt="Product Hero" width="100%"></img>
        </div>
      </HeroSection>

      <StickyMobileCta></StickyMobileCta>

      <section className="relative bg-white py-20 px-6 text-gray-900">
        <h3 className="text-center font-bradford1 leading-relaxed m-auto max-w-4xl text-gray-500">
          {product.web_description}
        </h3>
      </section>

      <ProductImages images={JSON.parse(product.carousel_webpic)} />
  
      <section className="relative bg-white font-light px-8 lg:px-24 xl:px-48">
        <div className="flex flex-wrap border-b flex-col md:flex-row justify-between items-center pb-24 pt-16 md:py-24 ">
          <div className="w-full lg:w-1/4 pb-10">
            <h3 className="text-gray-600">
              {product.web_detail_header}
            </h3>
            <p className="text-gray-500 pt-8 pb-12">
              {product.web_detail}
            </p>
            <Link
              to="/design-builder"
              className="bg-gray-600 border border-gray-600 hover:bg-transparent text-white hover:text-gray-600 w-40 my-12 px-5 py-4 text-center font-bradford1 tracking-wide modal-button"
            >
              Design Your Modal
            </Link>
          </div>
          <div className="w-full lg:w-2/3 pt-12 md:pt-0">
          <ProductTour p = {product.link} />
          </div>
        </div>
      </section>

      <section className="relative bg-white py-12 md:py-32">
        <h3 className="text-center text-gray-600 font-light">
          Interior, exterior, <em className="font-bradford">superior.</em>
        </h3>

        <div className="flex flex-col justify-around items-center md:mt-12 md:flex-row">
          <div className="mt-8">
            <RoundCarousel
                materials={JSON.parse(product.web_cabinets)}
            ></RoundCarousel>
          </div>
          <div className="mt-8">
            <RoundCarousel
              materials={JSON.parse(product.web_exterior)}
            ></RoundCarousel>
          </div>
          <div className="mt-8">
            <RoundCarousel
              materials={JSON.parse(product.web_interior)}
            ></RoundCarousel>
          </div>
        </div>
      </section>

      <AmenIcons></AmenIcons>

      <section className="relative bg-white py-10 lg:py-32 px-8 lg:px-24 xl:px-48">
        <div className="flex justify-center flex-wrap">
          <div className="w-full md:w-1/2 font-light text-center md:text-left py-12 md:py-0">
            <h3 className="text-left">Technical specifications</h3>
          </div>
          <div className="w-full md:w-1/2 text-gray-500 font-light">
            <ul>
              <li className="pb-20">
              </li>
              <li className="border-b pb-10 md:max-w-lg">
                <img src={product.floor_plan_pic} alt="See Modals" style={{ "width": "100%" }}></img>
              </li>
              <li className="border-b pb-4 md:max-w-lg">
                <h5>Weight: {product.weight}</h5>
              </li>
              <li className="border-b py-4 md:max-w-lg">
                <h5>
                  Dimensions L/W/H: {product.dimentions}
                </h5>
              </li>
              <li className="border-b py-4 md:max-w-lg">
                <h5>Footage: {product.footage}</h5>
              </li>
              <li className="border-b py-4 md:max-w-lg">
                <h5>Footings: {product.footings}</h5>
              </li>
            </ul>
          </div>
        </div>

        <div className="relative">
          <h3 className="w-full md:w-1/2 font-light text-left md:text-left py-10 md:py-10">
            Features
          </h3>
          <ul className="flex flex-wrap">
            {
              product_features.map((feature, i) => (
                <li
                  key={i}
                  className="w-full lg:w-1/2 text-gray-500 font-light"
                >
                  <h5 className="border-b py-4 md:max-w-lg">{feature}</h5>
                </li>
              ))}
          </ul>
        </div>
      </section>

      <section
        className="relative text-center"
        style={{
          height: "65vh",
        }}
      >

        <img className="" style={{"height": "100%", "width": "100%", "object-fit": "cover"}} src={product.web_cover} alt="See Modals"></img>

        {/*

        <Image
          className="absolute h-full w-screen"
          objectFit="cover"
          fluid={cta.node.childImageSharp.fluid}
          alt="See Modals"
        ></Image>

         */}

        <div className="absolute inset-0 flex justify-center items-center z-10">
          <div>
            <h3 className="m-auto text-white font-light tracking-wide">
              Our plan
              <span className="font-bradford"> meets</span> your vision.
            </h3>
            <p className="text-white pt-4 pb-12 lg:px-0 px-6 max-w-lg m-auto font-light tracking-wide">
              Explore all of our floor plans and styles to find the perfect
              Modal for you.
            </p>
            <Link
              to="/design-builder"
              className="transition transition-delay-longer bg-white border border-white hover:bg-transparent text-white text-gray-600 hover:text-white w-40 mt-12 px-8 py-3 text-center font-bradford1 modal-button"
            >
              Design Your Modal
            </Link>
          </div>
        </div>
      </section>

      <ProductIcons></ProductIcons>
    </Layout>
    </>
  )
}


export default ProductTemplate