import React from "react"

export default function OneIcon({ color = "white", className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 179 72"
      className={className}
      style={{ fill: color }}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polygon points="7 12 3.5 12 0 12 0 61 7 61 7 12" />
          <polygon points="111 12 104 12 104 61 107.5 61 111 61 111 12" />
          <polygon points="172 12 172 61 179 61 179 12 175.5 12 172 12" />
          <rect y="65" width="179" height="7" />
          <rect width="179" height="7" />
        </g>
      </g>
    </svg>
  )
}
