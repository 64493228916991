import React from "react"
import StudioIcon from "../images/studio-icon"
import OneIcon from "../images/one-icon"
import TwoIcon from "../images/two-icon"
import { Link } from "gatsby"

export default function ProductIcons() {
  return (
    <section className="relative flex flex-col items-center justify-around bg-gray-500 py-32 text-white md:flex-row">
      <Link
        to="/products/modal-00"
        className="opacity-75 hover:opacity-100"
        style={{ transition: "opacity 150ms linear" }}
      >
        <StudioIcon className="h-16"></StudioIcon>
        <h3 className="text-base text-center mt-2 font-maxeville">Modal 00</h3>
      </Link>

      <Link
        to="/products/modal-01"
        className="mt-16 opacity-75 hover:opacity-100 md:mt-0"
        style={{ transition: "opacity 150ms linear" }}
      >
        <OneIcon className="h-16"></OneIcon>
        <h3 className="text-base text-center mt-2 font-maxeville">Modal 01</h3>
      </Link>

      <Link
        to="/products/modal-02"
        className="mt-16 opacity-75 hover:opacity-100 md:mt-0"
        style={{ transition: "opacity 150ms linear" }}
      >
        <TwoIcon className="h-16"></TwoIcon>
        <h3 className="text-base text-center mt-2 font-maxeville">Modal 02</h3>
      </Link>
    </section>
  )
}
