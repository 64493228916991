import React from "react"

export default function AmenTwoIcon({ color = "#656b68", className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 90"
      className={className}
      style={{ fill: color }}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect x="13.97" width="25.29" height="9.05" />
          <rect x="13.97" y="41.02" width="59.63" height="9.05" />
          <rect x="48.31" width="25.29" height="9.05" />
          <polygon points="78.52 0 78.52 18.09 9.05 18.09 9.05 0 0 0 0 59.02 9.05 59.02 9.05 27.14 78.52 27.14 78.52 59.02 87.57 59.02 87.57 0 78.52 0" />
        </g>
      </g>
    </svg>
  )
}
