import React from "react"
import Image from "gatsby-image"

const ProductTour = ({ p }) => {

  //if (!show) {
  //  return null
  //}

  //const showIframe = !!src
  //const showImage = !showIframe && image

  if (p == "modal-pod") { var tour_link = "https://my.matterport.com/show/?m=Tjo9MH9CvMN"}
  if (p == "modal-00") { var tour_link = "https://my.matterport.com/show/?m=Tjo9MH9CvMN"}
  if (p == "modal-01") { var tour_link = "https://my.matterport.com/show/?m=9x1Ue6LhAL2"}
  if (p == "modal-02") { var tour_link = "https://my.matterport.com/show/?m=9x1Ue6LhAL2"}

  //00 https://my.matterport.com/show/?m=Tjo9MH9CvMN
  //01 Null
  //02 https://my.matterport.com/show/?m=9x1Ue6LhAL2

  //<iframe width="853" height="480" src={tour_link} frameborder="0"></iframe>

  return (
    <>
     <iframe width="100%" height="480" src={tour_link}></iframe>
    </>
  )
}

export default ProductTour
