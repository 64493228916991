import React from "react"
import Slick from "react-slick"
import Image from "gatsby-image"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default function ProductImages({ images }) {
  const settings = {
    infinite: true,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    speed: 500,
    swipeToSlide: true,
    arrows: false,
    draggable: true,
  }

  return (
    <section className="relative bg-white w-screen">
      <Slick {...settings}>
        {images &&
          images.map(image => {
            return (
              <img src={image} alt="Product Hero" width="100%"></img>
            )
          })}
      </Slick>
    </section>
  )
}

{
  /* <Image
                  key={image.img.id}
                  className="image-child absolute w-screen-70 z-0 h-full flex-none m-2 md:m-4 md:mx-6 md:w-screen-60 product-slider"
                  fluid={image.img.childImageSharp.fluid}
                  alt={image.desc}
                /> */
}
