import React from "react"
import AmenOneIcon from "../images/amenity-icon-1"
import AmenTwoIcon from "../images/amenity-icon-2"
import AmenThreeIcon from "../images/amenity-icon-3"

export default function AmenIcons() {
  return (
    <section className="relative flex flex-col items-center justify-around bg-gray-100 py-10 lg:py-32 text-white md:flex-row px-6">
      <div className="flex-col max-w-xs text-center justify-center">
        <AmenOneIcon className="h-20 m-auto mb-4 mt-10 md:mt-0 md:mb-10"></AmenOneIcon>
        <h5 className="font-light text-gray-700">
          Modern and energy-efficient appliances included.
        </h5>
      </div>
      <div className="flex-col max-w-xs text-center justify-center">
        <AmenThreeIcon className="h-20 m-auto mb-4 mt-10 md:mt-0 md:mb-10"></AmenThreeIcon>
        <h5 className="font-light text-gray-700">
          Sustainable, durable quartz countertops come standard.
        </h5>
      </div>
      <div className="flex-col max-w-xs text-center justify-center">
        <AmenTwoIcon className="h-20 m-auto mb-4 mt-10 md:mt-0 md:mb-10"></AmenTwoIcon>
        <h5 className="font-light text-gray-700">
          Custom built-in seating, bed, and storage.
        </h5>
      </div>
    </section>
  )
}
