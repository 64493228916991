import React from "react"

export default function AmenOneIcon({ color = "#656b68", className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 90"
      className={className}
      style={{ fill: color }}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect x="14.57" width="60.84" height="9.3" />
          <rect x="14.57" y="14.36" width="60.84" height="5.06" />
          <polygon points="0 0 0 90 9.3 90 9.3 0 4.65 0 0 0" />
          <rect x="14.57" y="80.7" width="60.84" height="9.3" />
          <polygon points="85.35 0 80.7 0 80.7 90 90 90 90 0 85.35 0" />
          <rect x="53.74" y="45" width="5.06" height="10.12" />
          <path d="M54.29,72.06A23.89,23.89,0,1,0,45,73.93,23.88,23.88,0,0,0,54.29,72.06Zm-28.17-22A18.88,18.88,0,1,1,45,68.93,18.87,18.87,0,0,1,26.12,50.06Z" />
        </g>
      </g>
    </svg>
  )
}
